<template>
  <div class="page-body">
    <div class="table-body">
      <div class="table-header">
        <div class="handle-box">
          <table width="100%">
            <tr>
              <td style="width: 80px; text-align: center">合同编号：</td>
              <td style="width: 28%">
                <el-input
                  v-model="searchItem.contractNum"
                  size="small"
                  placeholder="请输入合同申请编号"
                ></el-input>
              </td>
              <td style="width: 80px; text-align: center">合同类型：</td>
              <td style="width: 28%">
                <template>
                  <el-select
                    v-model="searchItem.contractType"
                    style="width: 100%"
                    size="small"
                    placeholder="请选择合同类型"
                  >
                    <el-option
                      v-for="item in gatherTypes"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </template>
              </td>
              <td style="width: 80px; text-align: center">项目名称：</td>
              <td style="width: 28%">
                <el-input
                  v-model="searchItem.projectName"
                  size="small"
                  placeholder="请输入项目名称"
                ></el-input>
              </td>
              <td style="text-align: center; width: 200px">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  size="small"
                  @click="searchBtn"
                  >搜索
                </el-button>
                <el-button
                  type="primary"
                  icon="el-icon-refresh-right"
                  size="small"
                  @click="resetBtn"
                >
                  重置
                </el-button>
                <el-button type="primary" icon="el-icon-download" size="small" @click="exportExcel">导出为Excel文件</el-button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="table-info">
        <div class="table-button">
          <div>
            <el-table
              :data="tableData"
              :max-height="tableHeight"
              style="width: 100%; height: 100%"
              :border="true"
              :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
              :cell-style="{ padding: '0', height: lineHeight }"
            >
              <el-table-column
                type="index"
                label="序号"
                width="50"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="contractNum"
                label="合同编号"
                width="180"
                align="center"
              ></el-table-column>
              <el-table-column
                prop="contractType"
                label="合同类型"
                width="120"
                align="center"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.contractType == 0">销售合同</span>
                  <span v-if="scope.row.contractType == 1">技术服务合同</span>
                  <span v-if="scope.row.contractType == 2">部件买卖合同</span>
                  <span v-if="scope.row.contractType == 3">售后合同</span>
                </template>
              </el-table-column>
              <el-table-column
                label="应收账款(元)"
                width="140"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.contractReceivables != 0">{{
                    (scope.row.contractReceivables / 100).toFixed(2)
                  }}</span>
                  <span v-if="scope.row.contractReceivables == 0">0</span>
                </template>
              </el-table-column>
              <el-table-column
                label="累计收款(元)"
                width="140"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.receivedAmount != 0">{{
                    (scope.row.receivedAmount / 100).toFixed(2)
                  }}</span>
                  <span v-if="scope.row.receivedAmount == 0">0</span>
                </template>
              </el-table-column>
              <el-table-column
                label="开票金额(元)"
                width="140"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.invoiceAmount != 0">{{
                    (scope.row.invoiceAmount / 100).toFixed(2)
                  }}</span>
                  <span v-if="scope.row.invoiceAmount == 0">0</span>
                </template>
              </el-table-column>
              <el-table-column
                label="财务应收款(元)"
                width="140"
                align="right"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.financeReceivables != 0">{{
                    (scope.row.financeReceivables / 100).toFixed(2)
                  }}</span>
                  <span v-if="scope.row.financeReceivables == 0">0</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="companyName"
                label="公司名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="projectName"
                label="项目名称"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                label="回款率"
                width="140"
                show-overflow-tooltip
                align="right"
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.contractReceivables != 0"
                    >{{
                      (
                        (
                          scope.row.receivedAmount /
                          scope.row.contractReceivables
                        ).toFixed(2) * 100
                      ).toFixed()
                    }}%</span
                  >
                  <span v-else>0</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="id"
                label="操作"
                width="100"
                align="center"
              >
                <template slot-scope="scope">
                  <div class="opertion">
                    <i
                      class="iconfont iconxiangqing"
                      title="查看详情"
                      @click="showGather(scope.row.id)"
                    ></i>
                    <i
                      class="iconfont iconzengjia"
                      title="增加回款"
                      style="margin-left: 10px"
                      v-if="hasAuthority('gather_record_add')"
                      @click="addGather(scope.row.id, scope.row.contractType)"
                    ></i>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="table-data">
          <Pagination
            :pageSize="option.pageSize"
            :pageTotal="totalPage"
            :pageCurrent.sync="option.pageIndex"
            :pageList="pageList"
            @handlePageChange="pageChange"
            @handleSizeChange="pageSizeChange"
          />
        </div>
      </div>
    </div>
    <div class="data-dialog">
      <el-dialog
        title="新增回款信息"
        :visible.sync="dialogVisible"
        width="65%"
        :close-on-click-modal="false"
        :show-close="false"
        center
      >
        <el-form
          :model="gatherForm"
          :rules="rules"
          ref="gatherForm"
          label-width="130px"
          class="demo-ruleForm"
        >
          <el-row>
            <el-col :span="10" style="width: 50%">
              <el-form-item
                size="small"
                label="回款金额(元):"
                prop="recoveriesAmount"
              >
                <el-input
                  size="small"
                  type="number"
                  v-model="gatherForm.recoveriesAmount"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="10" style="width: 50%">
              <el-form-item
                size="small"
                label="回款时间:"
                prop="recoveriesTime"
                style="width: 100%"
              >
                <el-date-picker
                  style="width: 100%"
                  v-model="gatherForm.recoveriesTime"
                  type="date"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm:ss"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
          <div
            v-if="rowContractType != 2"
            style="margin-bottom: 10px"
            @click="testF()"
          >
            设备回款情况
          </div>
          <template>
            <el-table
              v-if="rowContractType != 2"
              :data="deviceData"
              style="width: 100%"
              :border="true"
              max-height="380"
              :header-cell-style="{
                background: '#EBEEF8',
                height: '50px',
                'text-align': 'center',
              }"
              :cell-style="{ padding: '0', height: lineHeight }"
            >
			<el-table-column
                prop="projectName"
                label="项目名称"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="sceneNo"
                label="现场编号"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="imei"
                label="IMEI号"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="deviceType"
                label="设备类型"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deviceType == 1">塔机</span>
                  <span v-else-if="scope.row.deviceType == 2">升降机</span>
                  <span v-else-if="scope.row.deviceType == 3">单盲吊</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="deviceState"
                label="设备状态"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.deviceState == 0" style="color: #98fb98">使用中</span>
                  <span v-else style="color: red">已终止</span>
                </template>
              </el-table-column>
              <el-table-column
                label="应收款(元)"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <span v-if="scope.row.receivables != 0">{{
                    (scope.row.receivables / 100).toFixed(2)
                  }}</span>
                  <span v-if="scope.row.receivables == 0">0</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="received"
                label="已收款(元)"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="returnMoneyInfo"
                label="回款信息"
                align="center"
                show-overflow-tooltip
              ></el-table-column>
              <el-table-column
                prop="date"
                label="设备回款金额(元)"
                align="center"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  <el-input
                    type="number"
                    size="small"
                    v-model="scope.row.count"
                    placeholder="请输入金额"
                  ></el-input>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-form-item
            size="small"
            label="备注:"
            prop="remark"
            style="padding-top: 10px"
          >
            <el-input
              size="small"
              type="textarea"
              row="3"
              v-model="gatherForm.remark"
            ></el-input>
          </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="cancel('gatherForm')">取 消</el-button>
          <el-button
            type="primary"
            @click="saveGather('gatherForm')"
            :loading="isDisabled"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="information">
      <el-dialog
        :title="showTitle"
        :visible.sync="infoVisible"
        width="70%"
        :close-on-click-modal="false"
        style="margin-top: -90px"
        center
      >
        <div class="dialog-body">
          <div class="basic-info">
            <div class="text-title">基础信息</div>
            <div>
              <table style="text-align: left">
                <tr>
                  <td style="padding: 10px; width: 120px">公司名称：</td>
                  <td style="width: 300px">
                    {{ gatherDisplay.companyName }}
                  </td>
                  <td style="padding: 10px; width: 120px">合同号：</td>
                  <td style="width: 170px">
                    {{ gatherDisplay.contractNum }}
                  </td>
                  <td style="padding: 10px; width: 120px">合同类型：</td>
                  <td style="width: 300px">
                    {{
                      gatherDisplay.contractType == 0
                        ? "銷售合同"
                        : gatherDisplay.contractType == 1
                        ? "技术服务合同"
                        : gatherDisplay.contractType == 2
                        ? "部件买卖合同"
                        : gatherDisplay.contractType == 3
                        ? "售后合同"
                        : "未知合同"
                    }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; width: 120px">应收账款(元)：</td>
                  <td style="width: 300px">
                    {{
                      gatherDisplay.contractReceivables == 0
                        ? 0
                        : (gatherDisplay.contractReceivables / 100).toFixed(2)
                    }}
                  </td>
                  <td style="padding: 10px; width: 120px">累计收款(元)：</td>
                  <td style="width: 170px">
                    {{
                      gatherDisplay.receivedAmount == 0
                        ? 0
                        : (gatherDisplay.receivedAmount / 100).toFixed(2)
                    }}
                  </td>
                  <td style="padding: 10px; width: 120px">开票金额(元)：</td>
                  <td style="width: 300px">
                    {{
                      gatherDisplay.invoiceAmount == 0
                        ? 0
                        : (gatherDisplay.invoiceAmount / 100).toFixed(2)
                    }}
                  </td>
                </tr>
                <tr>
                  <td style="padding: 10px; width: 120px">财务应收款(元)：</td>
                  <td style="width: 300px">
                    {{
                      gatherDisplay.financeReceivables == 0
                        ? 0
                        : (gatherDisplay.financeReceivables / 100).toFixed(2)
                    }}
                  </td>
                  <td style="padding: 10px; width: 120px">回款率：</td>
                  <td style="width: 300px">
                    {{
                      (gatherDisplay.contractReceivables == 0
                        ? 0
                        : (gatherDisplay.receivedAmount /
                            gatherDisplay.contractReceivables) *
                          100
                      ).toFixed()
                    }}%
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <div class="payment-table">
            <div class="text-title">回款信息</div>
            <template>
              <el-table :data="collectionRecord" border style="width: 100%">
                <el-table-column
                  prop="recoveriesTime"
                  label="时间"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{
                      changeDateFormat(scope.row.recoveriesTime)
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="回款金额(元)" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.recoveriesAmount != 0">{{
                      (scope.row.recoveriesAmount / 100).toFixed(2)
                    }}</span>
                    <span v-if="scope.row.recoveriesAmount == 0">0</span>
                  </template>
                </el-table-column>
                <el-table-column label="财务应收款(元)" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.financeReceivables != 0">{{
                      (scope.row.financeReceivables / 100).toFixed(2)
                    }}</span>
                    <span v-if="scope.row.financeReceivables == 0">0</span>
                  </template>
                </el-table-column>
              </el-table>
            </template>
          </div>
          <div class="payment-table">
            <div class="text-title">开票金额</div>
            <template>
              <el-table :data="billDate" border style="width: 100%">
                <el-table-column
                  prop="createTime"
                  :formatter="dateFormat"
                  label="申请时间"
                  align="center"
                >
                  <template slot-scope="scope">
                    <span>{{ changeDateFormat(scope.row.createTime) }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="开票金额(元)" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.invoiceAmount != 0">{{
                      (scope.row.invoiceAmount / 100).toFixed(2)
                    }}</span>
                    <span v-if="scope.row.invoiceAmount == 0">0</span>
                  </template>
                </el-table-column>
                <el-table-column label="开票类型" align="center">
                  <template slot-scope="scope">
                    <span v-if="scope.row.invoiceType == 0">普通发票</span>
                    <span v-if="scope.row.invoiceType == 1">专用发票</span>
                    <span v-if="scope.row.invoiceType == 2">电子发票</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="createUserName"
                  label="申请人员"
                ></el-table-column>
              </el-table>
            </template>
          </div>
        </div>

        <span slot="footer" class="dialog-footer">
          <el-button type="primary" @click="infoVisible = false"
            >确 定</el-button
          >
        </span>
      </el-dialog>
    </div>
    <div class="export">
      <el-dialog
        title="回款记录"
        :visible.sync="exportDialogVisible"
        width="53.8%"
        :close-on-click-modal="false"
        center
      >
        <div class="dialog-time">
            <el-date-picker
                    v-model="startDate"
                    type="date"
                    size="small"
                    :clearable="false"
                    placeholder="选择开始时间"
                    value-format="yyyy-MM-dd">
            </el-date-picker>
            至
            <el-date-picker
                    v-model="endDate"
                    type="date"
                    placeholder="选择结束时间"
                    size="small"
                    :clearable="false"
                    value-format="yyyy-MM-dd">
            </el-date-picker>
             <el-button type="primary" size="mini" style="margin-left:15px" @click="dialogFilter">筛 选</el-button
          >
        </div>
          <el-table
            :data="excelTableData"
            max-height="400px"
            size="small"
            row-class-name="row"
            cell-class-name="column"
            :highlight-current-row="true"
            fit
          >
            <el-table-column
              v-for="(item, index) in jsonFields"
              :key="index"
              :prop="item.prop"
              :label="item.label"
              show-overflow-tooltip
            >
            </el-table-column>
          </el-table>
          <!-- 上面可以自定义自己的样式，还可以引用其他组件button -->
          <download-excel
                style="width: 80px;margin: 0px auto;margin-top: 10px;"
                :data="json_data"
                :fields="json_fields"
                name="回款记录"
                >
            <el-button
                type="primary"
                size="small"
                @click="exportDialogVisible = false"
                >导出数据
            </el-button>
        </download-excel>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import dateFormat from '../../assets/js/dateFormat'
export default {
  data() {
    return {
        excelTableData:[],
        startDate:'',
        endDate:'',
      rowContractType: 0,
      pageList: [16, 20, 30, 50],
      deviceData: [],
      tableHeight: "",
      lineHeight: "",
      isDisabled: false,
      showTitle: "",
      jsonFields: [
        { label: "合同号", prop: "contractNum" },
        { label: "合同名称", prop: "contractName" },
        { label: "客户名称", prop: "customerName" },
        { label: "开票日期", prop: "invoicingTime" },
        { label: "已开票金额(元)", prop: "invoicingAmount" },
        { label: "回款日期", prop: "recoveriesTime" },
        { label: "回款金额(元)", prop: "recoveriesAmount" },
      ],
      fields: [],
      values: [],
      json_fields: {},
      json_data: [],
      json_meta: [
        [
          {
            " key ": " charset ",
            " value ": " utf- 8 ",
          },
        ],
      ],
      exportDialogVisible: false,
      basicTable: [],
      collectionRecord: [],
      billDate: [],
      checkAll: false,
      functions: [],
      funNameList: [],
      isIndeterminate: true,
      dialogVisibles: false,
      shippingVisible: false,
      infoVisible: false,
      multipleSelection: [],
      delList: [],
      // totalPage:0,
      gatherForm: {
        contractId: "",
        recoveriesAmount: "",
        recoveriesTime: "",
        recoveriesDeviceStr: "",
      },
      searchItem: {
        contractNum: "",
        contractType: "",
        projectName: "",
        startDate: "",
        endDate: "",
        pageSize: 16,
        pageIndex: 1,
      },
      totalPage: 30,
      dialogVisible: false,
      option: {
        pageIndex: "",
        pageSize: 16,
        currentPage: 2,
      },
      gatherTypes: [
        {
          label: "售后合同",
          value: 3,
        },
        {
          label: "部件买卖合同",
          value: 2,
        },
        {
          label: "技术服务合同",
          value: 1,
        },
        {
          label: "销售合同",
          value: 0,
        },
      ],
      UpDialogVisible: false,
      tableData: [],
      shippingData: [],
      gatherDisplay: {
        companyName: "",
        companyType: "",
        contractName: "",
        contractNum: "",
        contractType: "",
        contractReceivables: "",
        financeReceivables: "",
        id: "",
        invoiceAmount: "",
        receivedAmount: "",
        recoveriesRecordList: [],
        userName: "",
        userTel: "",
      },
      rules: {
        recoveriesAmount: [
          { required: true, message: "回款金额不能为空", trigger: "blur" },
        ],
        recoveriesTime: [
          { required: true, message: "回款时间能为空", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    testF() {
      for (var item of this.deviceData) {
        item.count = this.deviceData[0].count;
      }
    },
    cancel(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
    changeDateFormat(date) {
      if (date == null || date == "" || date == undefined) {
        return "";
      } else {
        let dt = new Date(date);
        var year = dt.getFullYear();
        var month =
          dt.getMonth() + 1 < 10
            ? 0 + "" + (dt.getMonth() + 1)
            : dt.getMonth() + 1;
        var date = dt.getDate() < 10 ? 0 + "" + dt.getDate() : dt.getDate();
        return year + "-" + month + "-" + date;
      }
    },
    dateFormat(row, column) {
      let data = row[column.property];
      let dt = new Date(data);
      var year = dt.getFullYear();
      var month =
        dt.getMonth() + 1 < 10
          ? 0 + "" + (dt.getMonth() + 1)
          : dt.getMonth() + 1;
      var date = dt.getDate() < 10 ? 0 + "" + dt.getDate() : dt.getDate();
      return year + "-" + month + "-" + date;
    },
    resetBtn() {
      (this.searchItem = {
        contractNum: "",
        contractType: "",
        projectName: "",
        startDate: "",
        endDate: "",
        pageSize: 16,
        pageIndex: 1,
      }),
        this.pageChange(1);
    },
    saveGather(formName) {
      this.isDisabled = true;
      this.$refs[formName].validate((item) => {
        if (item) {
          var total = 0;
          var totalStr = "";
          debugger;
          if (this.rowContractType != 2) {
            for (var item of this.deviceData) {
              if (
                item.count != "" &&
                item.count != undefined &&
                item.count != 0
              ) {
                total = total + item.count * 1;
                if (totalStr == "") {
                  // id   imei

                  totalStr =
                    item.sceneNo +
                    "," +
                    item.id +
                    "," +
                    item.imei +
                    "," +
                    Math.round(item.count * 100);
                } else {
                  totalStr =
                    totalStr +
                    "|" +
                    item.sceneNo +
                    "," +
                    item.id +
                    "," +
                    item.imei +
                    "," +
                    Math.round(item.count * 100);
                }
              }
            }
            if (this.gatherForm.recoveriesAmount != total) {
              this.$message.error(
                "设备金额之和与输入的总金额不符，请核对后进行保存"
              );
              this.isDisabled = false;
              return;
            }
          }
          this.gatherForm.recoveriesDeviceStr = totalStr;
          var gatherData = JSON.parse(JSON.stringify(this.gatherForm));
          gatherData.recoveriesAmount = Math.round(gatherData.recoveriesAmount * 100);
          this.$api.addCollection(gatherData).then((res) => {
            if (res.code == 200) {
              this.pageChange("refresh");
              this.dialogVisible = false;
              this.$message.success(res.message);
              this.isDisabled = false;
            } else {
              this.$message.error(res.message);
              this.isDisabled = false;
            }
          });
        } else {
          this.$message.error("时间和金额不能为空，请仔细查看");
          this.isDisabled = false;
        }
      });
    },
    addGather(id, contractType) {
      this.isDisabled = false;
      this.dialogVisible = true;
      this.rowContractType = contractType;
      this.gatherForm = {
        contractId: id,
        recoveriesAmount: "",
        recoveriesTime: "",
        recoveriesDeviceStr: "",
      };
      this.$api.getAllDevicesList({ id: id }).then((res) => {
        if (res.code == 200) {
          for (var item of res.data) {
            item.count = 0;
          }
          res.data.map(item=>{
              item.received = item.received==0?0:((item.received/100).toFixed(2))
          })
          this.deviceData = res.data;
        }
      });
    },
    pageChange(option) {
      if(option != "refresh"){
            this.option.pageIndex = option;
            this.searchItem.pageIndex = option;
        }
      this.$api.pageGather(this.searchItem).then((res) => {
        if (res.code == 200) {
          for (var index = 0; index < res.data.content.length; index++) {
            res.data.content[index].financeReceivables =
              res.data.content[index].invoiceAmount;
          }
          this.tableData = res.data.content;
          this.totalPage = res.data.totalElements;
        }
      });
    },
    pageSizeChange(val) {
      this.searchItem.pageSize = val;
      this.$api.pageGather(this.searchItem).then((res) => {
        if (res.code == 200) {
          for (var index = 0; index < res.data.content.length; index++) {
            res.data.content[index].financeReceivables =
              res.data.content[index].invoiceAmount;
          }
          this.tableData = res.data.content;
          this.totalPage = res.data.totalElements;
        }
      });
    },
    chooseSelection(val) {
      this.multipleSelection = val;
    },
    //导出excel
    exportExcel() {
      this.exportDialogVisible = true;
      var day1 = new Date();
      day1.setDate(day1.getDate() + 1);
      this.startDate = dateFormat("YYYY-mm-DD", new Date())
      this.endDate = dateFormat("YYYY-mm-dd ", day1)
      this.$api.recoveriesInvoicingRecord({startDate:this.startDate,endDate:this.endDate}).then(res=>{
          if(res.code == 200){
              res.data.forEach(item => {
                  item.recoveriesAmount = (item.recoveriesAmount/100).toFixed(2)
                  item.invoicingAmount = (item.invoicingAmount/100).toFixed(2)
              });
              this.excelTableData = res.data
              this.json_data = res.data
          }
      })
    },
    //导出excel的筛选
    dialogFilter(){
        this.$api.recoveriesInvoicingRecord({startDate:this.startDate,endDate:this.endDate}).then(res=>{
          if(res.code == 200){
              res.data.forEach(item => {
                  item.recoveriesAmount = (item.recoveriesAmount/100).toFixed(2)
                  item.invoicingAmount = (item.invoicingAmount/100).toFixed(2)
              });
              this.excelTableData = res.data
              this.json_data = res.data
          }
      })
    },
    searchBtn() {
      this.pageChange(1);
    },
    showGather(id) {
      this.$api.getGatherById({ id }).then((res) => {
        if (res.code == 200) {
          this.gatherDisplay = {
            companyName: res.data.companyName,
            companyType: res.data.companyType,
            contractName: res.data.contractName,
            contractNum: res.data.contractNum,
            contractReceivables: res.data.contractReceivables,
            financeReceivables: res.data.invoiceAmount,
            id: res.data.id,
            invoiceAmount: res.data.invoiceAmount,
            receivedAmount: res.data.receivedAmount,
            recoveriesRecordList: [],
            userName: res.data.userName,
            userTel: res.data.userTel,
          };
          for (var item of res.data.recoveriesRecordList) {
            item.financeReceivables = res.data.invoiceAmount;
          }
          this.billDate = res.data.invoiceList;
          this.collectionRecord = res.data.recoveriesRecordList;
          this.showTitle = "《" + res.data.contractNum + "》" + "回款详情";
        }
      });
      this.infoVisible = true;
    },

    handleCheckAllChange(val) {
      this.gatherDisplay.demand = val ? this.functions : [];
      this.isIndeterminate = false;
    },
    handleCheckedFunctionsChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.functions.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.functions.length;
    },
    updateFormat(date) {
      var date = new Date(date);
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
  },
  created() {
    this.searchBtn();
    this.tableHeight = window.innerHeight * 0.76;
    this.jsonFields.map((item, i) => {
      this.json_fields[item.label] = item.prop;
    });
    if (window.screen.height > 900) {
      this.lineHeight = window.screen.height * 0.038 + "px";
    } else {
      this.lineHeight = window.screen.height * 0.033 + "px";
    }
  },
};
</script>

<style lang="scss" scoped>
.opertion {
  display: flex;
  justify-content: center;
}

.iconfont {
  font-family: "iconfont" !important;
  font-size: 20px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin-top: 3px;
  display: block;
  cursor: pointer;
}

.iconzengjia {
  color: green;
}

.page-body {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.table-body {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.table-header {
  height: 6%;
  margin-top: 0.5%;
  background-color: #ffffff;
}

.handle-box {
  padding: 5px;
  margin-left: 10px;
}

.text-title {
  font-size: 16px;
  font-weight: bold;
  color: #437ee6;
  text-align: center;
  margin-bottom: 20px;
}

.dialog-body {
  max-height: 650px;
  overflow-y: auto;
}

.table-info {
  height: 94%;
  margin-top: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.table-button {
  margin: 0 1%;
  flex: 1;
}

.table-data {
  height: 6%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.handle-input {
  width: 260px;
  display: inline-block;
}

.handle-input-date {
  width: 150px;
  display: inline-block;
  padding-right: 0px;
}

.information {
  background-color: #f0f2f5;
}

.dialog-title {
  margin: 0 20px;
  color: #67add9;
  font-size: 20px;
  /*border: 1px red solid;*/
}

.payment-table,
.approval-table {
  margin-top: 10px;
}

.basic-info .el-button {
  background: linear-gradient(to bottom, #39aace, #e1e3e3);
  border: 1px blue solid;
}

.payment-table .el-button {
  border: 1px green solid;
  background: linear-gradient(to bottom, #39aace, #e1e3e3);
}

.approval-table .el-button {
  border: 1px black solid;
  background: linear-gradient(to bottom, #39aace, #e1e3e3);
}

.file-info {
  border: 1px yellow solid;
}

td {
  text-align: left;
  white-space: nowrap; /*控制单行显示*/
  overflow: hidden; /*超出隐藏*/
  text-overflow: ellipsis; /*隐藏的字符用省略号表示*/
}
</style>
